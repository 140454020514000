import styled from "styled-components";
import { theme } from "../../res/themes";

export const SThank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  width: 100%;
  height: fit-content;
  margin-top: 150px;
  margin-bottom: 100px;
  max-width: 1200px;
  @media (max-width: 1359px) {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    max-width: 510px;
  }
  @media (max-width: 539px) {
    flex-direction: column;
    max-width: 320px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin-top: 50px;
  @media (max-width: 1359px) {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    max-width: 510px;
  }
  @media (max-width: 539px) {
    flex-direction: column;
    max-width: 320px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: bold;

  color: ${theme.colors.text_color};
  text-transform: uppercase;
  @media (max-width: 1359px) {
    font-size: 25px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;
export const ImageWrap = styled.div`
  width: 100%;
  height: 420px;
  border-radius: 10px;
  margin-bottom: 30px;
  max-width: 700px;
  /* opacity: 0;
  animation: fade-in 1s ease-in-out forwards; */
  @media (max-width: 1359px) {
    height: 300px;
    max-width: 500px;
    font-size: 34px;
  }
  @media (max-width: 767px) {
    height: 190px;
    max-width: 320px;
    font-size: 26px;
  }
  @media (max-width: 539px) {
    max-width: 320px;
    font-size: 26px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 10px;

  @media (max-width: 1359px) {
    font-size: 34px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

export const Paragraph = styled.p`
  height: 100%;
  width: 100%;
  font-family: "Noto Serif";
  font-style: italic;
  color: ${theme.colors.text_color};

  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 30px;

  @media (max-width: 1359px) {
    font-size: 20px;
    line-height: 35px;
    max-width: 700px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
    max-width: 500px;
  }
  @media (max-width: 539px) {
    margin-top: 15px;
    line-height: 30px;
    margin-bottom: 10px;
    max-width: 320px;
  }
  a {
    user-select: all;
    text-decoration: none;
    color: ${theme.colors.highlighted};
    margin-left: 10px;
  }
`;
export const Paragraph1 = styled.p`
  width: 100%;
  font-family: "Noto Serif";
  color: ${theme.colors.text_color};
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 20px;
  margin: 0;

  @media (max-width: 1359px) {
    font-size: 20px;
    line-height: 35px;
    max-width: 700px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
    max-width: 500px;
  }
  @media (max-width: 539px) {
    margin-top: 15px;
    line-height: 30px;

    max-width: 320px;
  }
  a {
    user-select: all;
    text-decoration: none;
    color: ${theme.colors.highlighted};
    margin-left: 10px;
  }
`;
