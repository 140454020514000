export const theme = {
  colors: {
    text_color: "#FFFFFF",
    highlighted: "#e9e2c2",
    light_background: "#363636",
    dark_background: "#1f1d1d",
    line: "#722e1d",
  },

  //   colors: {
  //     text_color: "#363636",
  //     highlighted: "#ff6600",
  //     light_background: "#FFFFFF",
  //     dark_background: "#363636",
  //   },
};
